import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";

import "./css/custom.scss";
import AOS from "aos";
import "aos/dist/aos.css";


// i18n labels
import en from "@/i18n/en.json";
import de from "@/i18n/de.json";

import VueScrollmagic from "vue-scrollmagic";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faMapMarkerAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faInstagram,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
    faMapMarkerAlt,
    faPhoneAlt,
    faLinkedin,
    faInstagram,
    faFacebookSquare
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

// i18n configuration
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "en",
  messages: {
    en,
    de,
  },
});

Vue.use(VueScrollmagic);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  created() {
    AOS.init();
  },
  render: (h) => h(App),
}).$mount("#app");


// Require dependencies
var VueCookie = require('vue-cookie');
// Tell Vue to use the plugin
Vue.use(VueCookie);
