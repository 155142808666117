<template>
  <div class="loader">
    <div id="progress-container">
      <img class="txt-logo" src="~@/assets/loader.svg" alt="" />
      <p class="txt-perc">{{ $store.state.percentage }}%</p>
      <div class="progress"><span id="bar"></span></div>
    </div>
  </div>
</template>

<script>
import { TimelineMax, TweenLite } from "gsap";
export default {
  data() {
    return {
      progressTl: "",
      progressBar: "",
      progressContainer: "",
      logo: "",
      percentText: "",
      vertMenu: "",
      loadingProgress: "",
      loader: "",
    };
  },
  methods: {
    /* loadComplete() {
      console.log('caricamento completato')
      var e = new TimelineMax()
      e.to(this.progressContainer, 0.3, {
        y: 100,
        autoAlpha: 0,
        ease: Back.easeIn
      })
        .to(
          this.logo,
          0.3,
          {
            y: 100,
            autoAlpha: 0,
            ease: Back.easeIn
          },
          0.1
        )
        .to(
          this.percentText,
          0.3,
          {
            y: 100,
            autoAlpha: 0,
            ease: Back.easeIn
          },
          0.1
        )
        .to(this.loader, 0.7, {
          yPercent: 100,
          ease: Power4.easeInOut
        })
        .set(this.loader, {
          opacity: 0
        })
      setTimeout(() => {
        this.$store.commit('setLoading', false)
        console.log('Loaded' + this.$store.state.loaded)
        console.log('Loading' + this.$store.state.loading)
      }, 1300)
    }, */
    updateProgress() {
      TweenLite.to(this.progressTl, 0.7, {
        progress: this.$store.state.percentage / 100,
      });
    },
  },
  mounted() {
    this.progressBar = document.getElementById("bar");
    this.progressContainer = document.getElementsByClassName("progress");
    this.logo = document.getElementsByClassName("txt-logo");
    this.percentText = document.getElementsByClassName("txt-perc");
    this.loader = document.getElementsByClassName("loader");

    this.progressTl = new TimelineMax({
      paused: !0,
    });
    this.progressTl.to(this.progressBar, 1, {
      width: 100,
    });
  },
  watch: {
    "$store.state.percentage": function() {
      this.updateProgress();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #212121;
  z-index: 99999;
  #progress-container {
    position: absolute;
    top: 30%;
    height: 40%;
    width: 100%;
    img {
      max-width: 8%;
    }

    .progress {
      width: 100px;
      height: 3px;
      background-color: rgba(255, 255, 255, 0.2);
      margin: 0 auto 15px;
      #bar {
        display: block;
        width: 0;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
    .txt-perc {
      font-size: 35px;
      font-family: Anita, sans-serif;
      letter-spacing: 2px;
      opacity: 1;
      margin: 20px auto;
      max-width: 400px;
      text-align: center;
    }
  }
}
</style>
