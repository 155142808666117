import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/history",
    name: "History",
    component: () =>
        import(/* webpackChunkName: "history" */ "../views/History.vue"),
  },
  {
    path: "/rotors",
    name: "Rotors",
    component: () =>
        import(/* webpackChunkName: "rotors" */ "../views/Rotors.vue"),
  },
  {
    path: "/calipers",
    name: "Calipers",
    component: () =>
        import(/* webpackChunkName: "calipers" */ "../views/Calipers.vue"),
  },
  {
    path: "/a370",
    name: "A370",
    component: () => import(/* webpackChunkName: "A370" */ "../views/A370.vue"),
  },
  {
    path: "/a400",
    name: "A400",
    component: () => import(/* webpackChunkName: "A400" */ "../views/A400.vue"),
  },
  {
    path: "/a410",
    name: "A410",
    component: () => import(/* webpackChunkName: "A410" */ "../views/A410.vue"),
  },
  {
    path: "/a420",
    name: "A420",
    component: () => import(/* webpackChunkName: "A420" */ "../views/A420.vue"),
  },
  {
    path: "/cooling",
    name: "Cooling",
    component: () =>
        import(/* webpackChunkName: "cooling" */ "../views/Cooling.vue"),
  },
  {
    path: "/pads",
    name: "Pads",
    component: () => import(/* webpackChunkName: "pads" */ "../views/Pads.vue"),
  },
  {
    path: "/epb",
    name: "EPB",
    component: () => import(/* webpackChunkName: "epb" */ "../views/EPB.vue"),
  },
  {
    path: "/oes",
    name: "OES",
    component: () => import(/* webpackChunkName: "oes" */ "../views/OES.vue"),
  },
  {
    path: "/projects",
    name: "Projects",
    component: () =>
        import(/* webpackChunkName: "projecs" */ "../views/Projects.vue"),
  },
  {
    path: "/upgrade-kit",
    name: "Upgrade Kit",
    component: () =>
        import(/* webpackChunkName: "upgrade-kit" */ "../views/UpgradeKit.vue"),
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: () =>
        import(/* webpackChunkName: "contact-us" */ "../views/ContactUs.vue"),
  },
  {
    path: "/official-dealers",
    name: "Official Dealers",
    component: () =>
        import(
            /* webpackChunkName: "official-dealers" */ "../views/OfficialDealers.vue"
            ),
  },
  {
    path: "/privacy-and-cookie-policy",
    name: "Privacy & Cookie Policy",
    component: () =>
        import(
            /* webpackChunkName: "privacy-and-cookie-policy" */ "../views/Privacy&Cookie.vue"
            ),
  },
  {
    path: "/mission-vision",
    name: "Mission & Vision",
    component: () =>
        import(/* webpackChunkName: "mission-vision" */ "../views/Mission&Vision.vue"
            ),
  },
  {
    path: "/ats-corsa-rr-turbo",
    name: "Ats Corsa RR Turbo",
    component: () =>
        import(/* webpackChunkName: "mission-vision" */ "../views/AtsCorsaRRTurbo.vue"
            ),
  },
  {
    path: "/aspark-owl",
    name: "Aspark Owl",
    component: () =>
        import(/* webpackChunkName: "mission-vision" */ "../views/AsparkOwl.vue"
            ),
  },
  {
    path: "/alfa-romeo-155-dtm",
    name: "Alfa Romeo 155 DTM",
    component: () =>
        import(/* webpackChunkName: "mission-vision" */ "../views/AlfaRomeo155Dtm.vue"
            ),
  },
  {
    path: "/aznom-palladium",
    name: "AZNOM Palladium",
    component: () =>
        import(/* webpackChunkName: "mission-vision" */ "../views/AznomPalladium.vue"
            ),
  },
  {
    path: "/kalmar-automotive",
    name: "Kalmar Automotive",
    component: () =>
        import(/* webpackChunkName: "mission-vision" */ "../views/KalmarAutomotive.vue"
            ),
  },
  {
    path: "/nissan-gt-r",
    name: "NISSAN GT-R",
    component: () =>
        import(/* webpackChunkName: "mission-vision" */ "../views/NissanGT-R.vue"
            ),
  },
  {
    path: "/kalmar",
    name: "KALMAR",
    component: () =>
        import(/* webpackChunkName: "mission-vision" */ "../views/kalmar.vue"
            ),
  },
  {
    path: "/sim-racing",
    name: "SIM RACING",
    component: () =>
        import(/* webpackChunkName: "mission-vision" */ "../views/SimRacing.vue"
            ),
  },
  {
    path: "/shop",
    name: "SHOP",
    component: () =>
        import(/* webpackChunkName: "mission-vision" */ "../views/Shop.vue"
            ),
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes,
});

export default router;
