<template>
  <div class="navigation">
    <div
        id="nav-icon"
        :class="{ open: isOpened === true }"
        @click="iconTrigger()"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div id="nav">
      <div id="logo" @click="closeMenu()">
        <svg
            version="1.1"
            id="Livello_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="41px"
            height="204px"
            viewBox="-195 400.2 41 204"
            style="enable-background:new -195 400.2 41 204;"
            xml:space="preserve"
        >
          <g>
            <path
                style="fill:#2756FF;"
                d="M-190.6,404.6c-3,3-4.4,6.6-4.4,10.8c0,3,2.5,5.5,5.5,5.5c0.8,0,1.5-0.2,2.3-0.5c1.2-0.4,5.3-2,6.4-2.4
              c1.7-0.5,3.1-1.9,3.7-3.6c0.4-1.1,2-5.2,2.4-6.4c0.3-0.7,0.5-1.5,0.5-2.3c0-1.5-0.6-2.8-1.6-3.9c-1-1-2.4-1.6-3.9-1.6
              C-183.8,400.2-187.5,401.7-190.6,404.6z"
            ></path>
            <path
                style="fill:#FFFFFF;"
                d="M-185.2,585.8v-12.1c0-1.7-1.4-3.1-3.1-3.1c-1.7,0-3.1,1.4-3.1,3.1v12.1c0,10.1,8.3,18.4,18.4,18.4
              c10.2,0,18.4-8.2,18.4-18.4v-12.1c0-1.7-1.4-3.1-3.1-3.1c-1.7,0-3,1.4-3,3.1v12.1c0,6.7-5.4,12.1-12.2,12.1
              C-179.7,598-185.2,592.5-185.2,585.8z"
            ></path>
            <path
                style="fill:#FFFFFF;"
                d="M-189.9,544c-1,0.5-1.6,1.6-1.6,2.7l0,0c0,1.1,0.6,2.1,1.6,2.7l22.5,12.6c1.5,0.8,3.4,0.3,4.2-1.2
              c0.8-1.5,0.3-3.4-1.2-4.2l-17.7-9.9l13.1-7.3v6.2c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1v-9.6l7-3.9c1-0.6,1.6-1.6,1.6-2.7
              c0-0.5-0.1-1-0.4-1.5c-0.8-1.5-2.7-2-4.2-1.2L-189.9,544z"
            ></path>
            <path
                style="fill:#FFFFFF;"
                d="M-179.7,494c-6.5,0-11.9,5.3-11.9,11.8V526c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1v-20.2
              c0-3.1,2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6v0.6v8.2c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1v-6.5l11.7-7.1c1-0.6,1.5-1.6,1.5-2.6
              c0-0.6-0.1-1.1-0.5-1.6c-0.9-1.5-2.8-1.9-4.3-1l-9.4,5.7C-170.4,497.1-174.7,494-179.7,494z"
            ></path>
            <path
                style="fill:#FFFFFF;"
                d="M-180.8,449.6c-5.9,0-10.8,4.8-10.8,10.7v20c0,1.7,1.4,3.1,3.1,3.1h30.7c1.7,0,3.1-1.4,3.1-3.1v-20
              c0-5.9-4.8-10.7-10.8-10.7c-3,0-5.7,1.2-7.7,3.2C-175.1,450.8-177.8,449.6-180.8,449.6z M-160.9,460.3v16.9h-24.5v-16.9
              c0-2.5,2-4.5,4.5-4.5s4.5,2,4.5,4.5v6.1c0,0.1,0,0.2,0,0.3v0.9c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1v-7.4
              c0-2.5,2-4.5,4.5-4.5C-163,455.7-160.9,457.8-160.9,460.3z"
            ></path>
            <path
                style="fill:#FFFFFF;"
                d="M-168.4,407.2c-0.8,1.5-0.2,3.4,1.3,4.2c1.2,0.6,2.2,1.4,3.1,2.3c4.9,4.9,4.9,12.9,0,17.8
              c-2.4,2.4-5.5,3.7-8.9,3.7c-3.3,0-6.5-1.3-8.9-3.7c-0.9-0.9-1.7-2-2.3-3.1c-0.8-1.5-2.6-2.1-4.2-1.3c-1.5,0.8-2.1,2.6-1.3,4.2
              c0.9,1.7,2,3.3,3.4,4.7c3.5,3.5,8.3,5.5,13.3,5.5c5,0,9.8-1.9,13.3-5.5c3.6-3.6,5.5-8.4,5.5-13.3s-1.8-9.6-5.5-13.3
              c-1.4-1.4-3-2.5-4.7-3.4C-165.7,405.1-167.6,405.7-168.4,407.2z"
            ></path>
          </g>
        </svg>
      </div>
      <div id="navTrigger" @click="openMenu()">
        <svg
            version="1.1"
            id="svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 41 40.7"
            xml:space="preserve"
        >
          <g>
            <path
                style="fill:#2756FF;"
                d="M36.5,4.4c-3-3-6.6-4.4-10.8-4.4c-3,0-5.5,2.5-5.5,5.5c0,0.8,0.2,1.5,0.5,2.3c0.4,1.2,2,5.3,2.4,6.4
              c0.5,1.7,1.9,3.1,3.6,3.7c1.1,0.4,5.2,2,6.4,2.4c0.7,0.3,1.5,0.5,2.3,0.5c1.5,0,2.8-0.6,3.9-1.6c1-1,1.6-2.4,1.6-3.9
              C41,11.1,39.5,7.4,36.5,4.4z"
            ></path>
            <path
                style="fill:#2756FF;"
                d="M34,26.4c-1.5-0.8-3.4-0.2-4.2,1.3c-0.6,1.2-1.4,2.2-2.3,3.1c-4.9,4.9-12.8,4.9-17.7,0
              c-2.4-2.4-3.7-5.5-3.7-8.8s1.3-6.5,3.7-8.8c0.9-0.9,2-1.7,3.1-2.3c1.5-0.8,2.1-2.6,1.3-4.2c-0.8-1.5-2.6-2.1-4.2-1.3
              c-1.7,0.9-3.3,2-4.7,3.4C1.9,12.4,0,17.1,0,22.1s1.9,9.7,5.5,13.2c3.6,3.6,8.4,5.5,13.2,5.5s9.6-1.8,13.2-5.5
              c1.4-1.4,2.5-3,3.4-4.7C36.1,29.1,35.5,27.2,34,26.4z"
            ></path>
          </g>
        </svg>
      </div>

      <LanguageSwitch />
    </div>
    <div id="nav-overlay">
      <div id="nav-content">

        <!--        <h3>
                <router-link @click.native="closeMenu()" to="/">{{
                  $t("home-title")
                }}</router-link>
                </h3>-->

        <router-link @click.native="closeMenu()" to="/projects">{{
            $t("projects-title-uppercase")
          }}</router-link>

        <div class="nav-title">
          <strong>{{$t("nav-products")}}</strong>
        </div>

        <router-link @click.native="closeMenu()" to="/calipers">{{
            $t("calipers-title")
          }}</router-link>
        <div id="nav-products">
          <router-link @click.native="closeMenu()" to="/a370" class="child">{{
              $t("a370-title")
            }}</router-link>
          <router-link @click.native="closeMenu()" to="/a400" class="child">{{
              $t("a400-title")
            }}</router-link>
          <router-link @click.native="closeMenu()" to="/a410" class="child">{{
              $t("a410-title")
            }}</router-link>
          <router-link @click.native="closeMenu()" to="/a420" class="child">{{
              $t("a420-title")
            }}</router-link>
        </div>
        <router-link @click.native="closeMenu()" to="/rotors">{{
            $t("rotors-title")
          }}</router-link>

        <router-link @click.native="closeMenu()" to="/pads">{{
            $t("pads-title")
          }}</router-link>

        <router-link @click.native="closeMenu()" to="/cooling">{{
            $t("cooling-title")
          }}</router-link>

        <router-link @click.native="closeMenu()" to="/epb">{{
            $t("epb-title")
          }}</router-link>
        <router-link @click.native="closeMenu()" to="/oes">{{
            $t("oes-title")
          }}</router-link>

        <!--        <router-link
                  @click.native="closeMenu()" class="child" to="/upgrade-kit">{{
                    $t("upgrade-kit-title")
                  }}</router-link>-->

        <a title="Carbobrake Shop" href="/shop">SHOP</a>


        <div class="nav-title"><strong>{{
            $t("nav-company")
          }}</strong></div>

        <router-link @click.native="closeMenu()" to="/history" >{{
            $t("history-title")
          }}</router-link>

        <router-link @click.native="closeMenu()" to="/mission-vision" >{{
            $t("mission-vision-title")
          }}</router-link>

        <router-link @click.native="closeMenu()" to="/contact-us" >{{
            $t("contact-us-title")
          }}</router-link>

        <router-link @click.native="closeMenu()" to="/official-dealers" >{{
            $t("dealers-title")
          }}</router-link>

        <router-link @click.native="closeMenu()" to="/kalmar" >{{
            $t("kalmar-main-title")
          }}</router-link>

        <router-link @click.native="closeMenu()" to="/sim-racing" >{{
            $t("sim-racing-main-title")
          }}</router-link>

        <ul class="social-menu">
          <li>
            <a
                title="Sicom on Facebook"
                href="https://www.facebook.com/CARBObrake/"
                target="_blank"
            >
              <font-awesome-icon :icon="['fab', 'facebook-square']" size="2x"
              /></a>
          </li>
          <li>
            <a
                title="Sicom on Linkedin"
                href="https://www.linkedin.com/company/sicom-automotive?trk=ppro_cprof"
                target="_blank"
                rel="nofollow"
            ><font-awesome-icon :icon="['fab', 'linkedin']" size="2x" />
            </a>
          </li>
          <li>
            <a
                title="Sicom on Instagram"
                href="https://www.instagram.com/carbobrake/"
                target="_blank"
                rel="nofollow"
            ><font-awesome-icon :icon="['fab', 'instagram']" size="2x" />
            </a>
          </li>
          <li></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitch from "../components/LanguageSwitch.vue";
import { TimelineMax, Power4, Power3 } from "gsap";
export default {
  name: "Footer",
  components: {
    LanguageSwitch,
  },
  data() {
    return {
      timeline: "",
      vertNav: "",
      navTriggerWrapper: "",
      navTrigger: "",
      navIcon: "",
      vertMenu: "",
      logo: "",
      isOpened: false,
      screenWidth: "",
    };
  },
  methods: {
    openMenu() {
      this.timeline.play().duration(2);
      this.isOpened = true;
    },
    closeMenu() {
      this.timeline.reverse().duration(1);
      this.isOpened = false;
    },
    iconTrigger() {
      if (this.timeline.reversed()) {
        this.timeline.play().duration(2);
        this.isOpened = true;
      } else {
        this.timeline.reverse().duration(1);
        this.isOpened = false;
      }
    },
  },
  mounted() {
    this.vertNav = document.getElementById("nav");
    this.navTriggerWrapper = document.getElementById("navTrigger");
    this.navTrigger = document.getElementById("svg");
    this.navIcon = document.getElementById("nav-icon");
    this.vertMenu = document.getElementById("nav-overlay");
    this.logo = document.getElementById("logo");

    this.timeline = new TimelineMax({
      paused: !0,
      reversed: !0,
    });
    this.timeline.to(this.navIcon, 0.1, {
      top: 10,
      ease: Power4.easeIn,
    });
    this.timeline.to(
        this.navTrigger,
        0.4,
        {
          rotation: -90,
          ease: Power4.easeIn,
        },
        0.1
    );
    this.timeline.fromTo(
        [this.vertNav, this.vertMenu],
        0.5,
        {
          left: "100%",
          x: -70,
        },
        {
          left: "0%",
          x: 0,
          ease: Power3.easeIn,
        }
    );
    this.timeline.fromTo(
        [this.vertMenu],
        0.5,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: Power3.easeIn,
        },
        0.5
    );
    this.timeline.to(
        this.navTrigger,
        0.1,
        {
          x: 5,
        },
        1
    );
    this.timeline.to(this.navTriggerWrapper, 0.8, {
      zIndex: 1,
    });
    this.timeline.to(
        this.logo,
        0.3,
        {
          opacity: 1,
        },
        1.4
    );
    /* this.timeline.to(this.navIcon, 0.3, {
      css: {
        className: '+=open'
      }
    }) */
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
