<template>
  <div id="app">
    <div id="main-container">
      <div id="header" v-if="!$store.state.loading && !this.isMobile">
        <router-link to="/"
        ><img src="~@/assets/logo_carbo_brake.svg" alt=""
        /></router-link>
      </div>

      <router-view :key="$route.fullPath" class="view" />
      <Footer v-if="$store.state.footerVisible" />
      <cookie-law
          v-if="!$store.state.loading"
          theme="dark-lime"
          ref="cookieBanner"
          v-on:accept="updateCookieConsent()"
      >
        <div class="cookie-container" slot-scope="props">
          <div class="cookie-texts">
            <p class="cookie-message">
              We use cookies on this site to enhance your user experience. By
              clicking "I agree" you are giving your consent for us to set
              cookies.
            </p>
          </div>
          <div class="cookie-buttons">
            <router-link to="/privacy-and-cookie-policy"
            ><button class=" Cookie__button more-info">
              <span>More info</span>
            </button></router-link
            >
            <button class="Cookie__button accept" @click="props.accept">
              <span>I agree</span>
            </button>
          </div>
        </div>
      </cookie-law>
      <Loader v-if="$store.state.loading" />
    </div>
    <NavBar v-if="!$store.state.loading && !this.isMobile" />
    <NavBarMobile v-if="!$store.state.loading && this.isMobile" />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import NavBar from "@/components/NavBar.vue";
import NavBarMobile from "@/components/NavBarMobile.vue";
import Loader from "@/components/Loader.vue";
import CookieLaw from "vue-cookie-law";


export default {
  components: {
    Footer,
    NavBar,
    NavBarMobile,
    Loader,
    CookieLaw,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    reportWindowSize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }

      /* if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i)
      ) {

      }  */
    },
    updateCookieConsent() {
      /*
      console.log(this.$store.state.cookiesAccepted) */
      this.$store.state.cookiesAccepted = true; /*
      console.log(this.$store.state.cookiesAccepted) */
    },
  },

  mounted() {
    this.reportWindowSize();
    window.addEventListener("resize", this.reportWindowSize);
    /*console.log(window.location.pathname);*/
    this.$router.push(window.location.pathname);
  },
  watch: {
    "$store.state.cookiesAccepted": function() {
      /* console.log('cookie cambiati') */
    },
  },
};
</script>

<style lang="scss"></style>
